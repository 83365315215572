import jwtDecode from 'jwt-decode';

const getLoggedInUser = () => {
  const kcKey = `oidc.user:${window._conf_.KEYCLOAK_AUTHORITY}:${window._conf_.KEYCLOAK_CLIENT_ID}`;
  const token = sessionStorage.getItem(kcKey);
  return token ? { ...jwtDecode(token) } : { roles: [] };
};

const getRoles = () => {
  const user = getLoggedInUser();
  return user.roles;
};

export const isAdmin = () => getRoles().includes('admin');

export const isAnalyst = () => getRoles().includes('analyst');

export const isOperator = () => getRoles().includes('operator');

export const isPlatformAdmin = () => getRoles().includes('platform_admin');

export const getLoggedUserId = () => {
  const user = getLoggedInUser();
  return user.identity?.id;
};

export const isGuestUser = () => {
  const user = getLoggedInUser();
  const isGuestUser = user.roles && user.roles.length === 0;
  return isGuestUser;
};
