import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackSetState } from 'contexts/SnackContext';
import { IconButton } from '@material-ui/core';
import { Copy, Copied } from 'common/Icons';
import { ListElement, ListElementText, ListElementActions } from 'components/List';
import Safety from 'pages/components/Safety';
import AnalysisStatus from 'pages/components/AnalysisStatus';
import { useClipboard } from 'hooks';
import { isGuestUser } from 'entities/auth/auth.utils';
import { propTypes } from './Item.props';
import NameColumn from './components/NameColumn';
import makeStyles from './Item.styles';

const useStyles = makeStyles();

const Item = ({ data }) => {
  const [t] = useTranslation();
  const isGuest = isGuestUser();
  const setSnack = useSnackSetState();
  const classes = useStyles();
  const copy = useClipboard();
  const [copied, setCopied] = useState(false);

  const copyClipboard = () => {
    copy({
      value: `${window.location.origin}/ui/details/url/${data.target.id}`
    });
    setCopied(true);
    setSnack({
      isOpen: true,
      title: t('url_copied_to_clipboard'),
      severity: 'success'
    });
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return <ListElement divider>
    <ListElementText
      primary={<NameColumn rowData={data.target} />}
      className={classes.urlName}
      style={{ width: '70%' }}
    />
    <ListElementText
      primary={<Safety rowData={{ current_analysis: data }} />}
      className={classes.status}
      classes={{ primary: classes.status }}
      style={{ width: isGuest ? '15%' : '30%' }}
    />
    <ListElementText
      primary={<AnalysisStatus rowData={{ current_analysis: data }} />}
      className={classes.status}
      classes={{ primary: classes.status }}
      style={{ width: isGuest ? '10%' : '20%' }}
    />
    {!isGuest && <ListElementActions>
      <IconButton
        color="secondary"
        aria-label="clipboard"
        onClick={copyClipboard}
      >
        { !copied && <Copy style={{ height: 25, width: 30 }} title={t('copy_to_clipboard')}/>}
        { copied && <Copied style={{ height: 25, width: 30 }} title={t('copied_to_clipboard')}/>}
      </IconButton>

    </ListElementActions> }
  </ListElement>;
};

Item.propTypes = propTypes;

export default Item;
