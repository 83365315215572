import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, FormControl, Grid, Link, Typography } from '@material-ui/core';
import { PlusSimple, URLGlobe } from 'common/Icons';
import { useAuthUser } from 'hooks';
import useScanContext from 'contexts/ScanContext/useScanContext';
import { SCAN_ACTIONS } from 'contexts/ScanContext/ScanContext';
import ScanResults from './components/ScanResults';
import UrlField from './components/UrlField/UrlField';
import Alert from 'components/Alert/Alert';
import AdvancedScanDrawer from 'common/scan/AdvancedScanDrawer';
import { ANONYMOUS_MAX_INPUTS, MAX_INPUTS } from './UrlScanner.constants';
import { propTypes } from './UrlScanner.props';
import makeStyles from './UrlScanner.styles';

const useStyles = makeStyles();

const UrlScanner = ({ isDisabled, value = '' }) => {
  const [t] = useTranslation();
  const [{ isAdvancedScan, status }, setScanContext] = useScanContext();
  const classes = useStyles();
  const [urls, setUrls] = useState([value]);
  const [apkScan, setApkScan] = useState(false);
  const [isUploadStarted, setIsUploadStarted] = useState();
  const { isUserLogged, checkIfAdvancedUser } = useAuthUser();
  const isGuestUser = !isUserLogged();
  const isAdvancedUser = checkIfAdvancedUser();

  const validUrls = useMemo(() => urls.filter(url => url !== '' && url !== null), [urls]);
  const maxInputsForUser = useMemo(() => isGuestUser ? ANONYMOUS_MAX_INPUTS : MAX_INPUTS, [isGuestUser]);
  const canAddLink = useMemo(() => urls.length < maxInputsForUser && !isDisabled, [urls, maxInputsForUser, isDisabled]);
  const canDeleteLink = useMemo(() => urls.length > 1, [urls]);
  const canStartScan = useMemo(() => validUrls.length === urls.length && !isDisabled, [validUrls, urls, isDisabled]);

  useEffect(() => {
    if (urls[0]) {
      setIsUploadStarted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsUploadStarted(status === 'scanning');
  }, [status]);

  const handleOnChangeUrl = useCallback((event, index) => {
    const currentUrl = event.target.value;
    const newUrlsList = urls.map((url, urlIndex) => {
      if (index === urlIndex) {
        return currentUrl;
      };
      return url;
    });
    setUrls(newUrlsList);
  }, [urls]);

  const removeInput = useCallback((index) => {
    const newUrlsList = urls.filter((_, urlIndex) => urlIndex !== index);
    setUrls(newUrlsList);
  }, [urls]);

  const scanApkUrl = (apkUrl) => {
    setApkScan(true);
    setUrls([apkUrl]);
    setIsUploadStarted(true);
  };

  const addEmptyInput = useCallback(() => {
    setUrls([...urls, '']);
  }, [urls]);

  const handleOnAddLink = useCallback(event => {
    event.preventDefault();
    addEmptyInput();
  }, [addEmptyInput]);

  const handleOnFormSubmit = useCallback(event => {
    event.preventDefault();
  }, []);

  const handleOnClick = useCallback(() => {
    setIsUploadStarted(true);
  }, []);

  const handleOnNewScan = useCallback(() => {
    setUrls(['']);
    setIsUploadStarted(false);
    setApkScan(false);
  }, []);

  const openAdvScanView = () => {
    setScanContext({ type: SCAN_ACTIONS.SELECT_URLS, payload: [...urls] }); // update context state after select all the files
    toggleAdvancedScan();
  };

  const toggleAdvancedScan = () => {
    setScanContext({ type: SCAN_ACTIONS.TOGGLE_ADVANCED_SCAN });
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
      {!isUploadStarted && !isAdvancedScan && <>
        <Grid item xs={8} style={{ textAlign: 'center' }}>
          <URLGlobe size={100}/>
          <div>
            <Typography
              variant="body1"
              align="center"
              gutterBottom
              className={classes.scanDescription}
            >
              <Trans i18nKey="scan_and_analyze_urls">
                Scan and analyze <strong>URLs</strong>.
              </Trans>
              {!isGuestUser &&
                <Trans i18nKey="scan_and_analyze_apk_urls">
                  You can also enter a URL that points to an APK on the <strong>Google Play store</strong> to scan the APK directly (Qflow will download and analyze the APK file itself)
                </Trans>
              }
            </Typography>
          </div>
        </Grid>
        <Grid item xs={8}>
          <form noValidate autoComplete="off" onSubmit={handleOnFormSubmit}>
            <FormControl>
              {urls.map((url, index) => (
                <UrlField
                  key={index}
                  index={index}
                  url={url}
                  onChange={handleOnChangeUrl}
                  onRemove={removeInput}
                  canDelete={canDeleteLink}
                  isDisabled={isDisabled}
                  isGuestUser={isGuestUser}
                  onScanApkUrl={scanApkUrl}
                  />
              ))}
            </FormControl>
            <div className={classes.addLinkWrapper}>
              <span>
                {canAddLink && (
                  <Link data-test-id='addUrlLink' href='#' onClick={handleOnAddLink} disabled>
                    <PlusSimple size={10}/>{t('add_link')}
                  </Link>
                )}
              </span>
              <span data-test-id='scanLimitInfo' className={classes.addLinkExplanation}>
                {t('we_can_scan_links_at_once', { numberOfLinks: maxInputsForUser, fractionUsed: `${urls.length}/${maxInputsForUser}` })}
              </span>
            </div>
            <div>
            {
              isAdvancedUser && canStartScan
                ? (
                <Typography
                  component="a"
                  color="primary"
                  gutterBottom
                  className={classes.assignText}
                  onClick={openAdvScanView}
                >
                  {t('assign_probes_to_all_urls')}
                </Typography>
                  )
                : null
              }
            </div>
            <FormControl variant="contained">
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                onClick={handleOnClick}
                className={classes.btnScanUrl}
                disabled={!canStartScan}
              >
               {t('scan_url')}
              </Button>
            </FormControl>
          </form>
          {isDisabled && (
            <div className={classes.noProbeWarningWrapper}>
              <Alert type={Alert.TYPES.WARNING} justify='center'>
                {t('scan_is_not_available_as_no_probes_are_enabled')}
              </Alert>
            </div>
          )}
        </Grid>
      </>}
      {!isUploadStarted && isAdvancedScan && <AdvancedScanDrawer type={'URL'} onClose={() => toggleAdvancedScan()} />}
      {isUploadStarted && <ScanResults urls={urls} onNewScan={handleOnNewScan} apkScan={apkScan} />}
    </Grid>
  );
};

UrlScanner.propTypes = propTypes;

export default UrlScanner;
