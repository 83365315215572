import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Filename,
  Size,
  Date
} from 'common/Table/Cells';
import CellMenu from './components/CellMenu';
import ThreatScore from 'pages/components/ThreatScore';
import SubmittedBy from './components/SubmittedBy';
import Tags from './components/Tags';
import AnalysisStatus from '../components/AnalysisStatus';
import tableConfig from 'common/tableConfig';

const GetFilenameColumn = (searchQuery) => {
  const [t] = useTranslation();
  return {
    title: t('filename'),
    width: '35%',
    sorting: false,
    cellStyle: {
      ...tableConfig.options.cellStyle,
      borderBottom: '1px solid #e0e0e0',
      maxWidth: 300,
      width: 300,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    // eslint-disable-next-line react/display-name
    render: rowData => <Filename rowData={rowData} searchQuery={searchQuery}/>
  };
};

const GetOwnerColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('submitted_by'),
    width: '10%',
    sorting: false,
    cellStyle: {
      ...tableConfig.options.cellStyle,
      borderBottom: '1px solid #e0e0e0',
      maxWidth: 130,
      width: 130
    },
    // eslint-disable-next-line react/display-name
    render: rowData =>
      <SubmittedBy owner={rowData?.file_endpoint_data?.owner}/>
  };
};

const GetThreatScoreColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('threat_score'),
    width: '10%',
    sorting: false,
    cellStyle: {
      ...tableConfig.options.cellStyle,
      borderBottom: '1px solid #e0e0e0',
      maxWidth: 130,
      width: 130
    },
    render: rowData => {
      return <ThreatScore rowData={rowData}/>;
    }
  };
};

const GetStatusColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('status'),
    width: '60px',
    sorting: false,
    cellStyle: {
      ...tableConfig.options.cellStyle,
      borderBottom: '1px solid #e0e0e0',
      width: '60px'
    },
    render: rowData => <AnalysisStatus rowData={rowData} />
  };
};

const GetSizeColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('size'),
    width: '10%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: rowData => <Size rowData={rowData}/>
  };
};

const GetDateColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('last_scan_date'),
    field: 'date',
    width: '20%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: rowData => <Date rowData={rowData}/>
  };
};

const GetTagsColumn = (refreshTableData) => {
  const [t] = useTranslation();
  return {
    title: t('tags'),
    width: '10%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: rowData => {
      return (
        <Tags tags={rowData?.tags || []} fileId={rowData?.id} onChange={refreshTableData}/>
      );
    }
  };
};

const GetMenuColumn = (refreshTableData) => {
  const [t] = useTranslation();
  return {
    title: t('actions'),
    width: '20%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: (rowData) => <CellMenu tags={rowData.tags} file={rowData} onChange={refreshTableData} />
  };
};

export const getColumns = (refreshTableData, searchQuery) => {
  return [
    GetFilenameColumn(searchQuery),
    GetOwnerColumn(),
    GetThreatScoreColumn(),
    GetStatusColumn(),
    GetSizeColumn(),
    GetDateColumn(),
    GetTagsColumn(refreshTableData),
    GetMenuColumn(refreshTableData)
  ];
};
