import React, { useEffect, useRef, useState } from 'react';
import MaterialTable from 'material-table';
import { useSnackSetState } from 'contexts/SnackContext';
import { Paper } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { TableLocalization } from 'common/tableConfig';
import { fetchURLs, fetchURL } from 'entities/urls/urls.api';
import { getRange, getItemsCount } from '../../../pages.utils';
import getTableProps from './UrlAnalysisTable.tableProps';
import makeStyles from './UrlAnalysisTable.styles';

const useStyles = makeStyles();

export default function UrlAnalysisTable () {
  const classes = useStyles();
  const localization = TableLocalization();
  const setSnack = useSnackSetState();
  const { state } = useLocation();
  const tableRef = useRef(null);
  const refreshTableData = () => tableRef.current.onQueryChange();
  const [searchQuery, setSearchQuery] = useState(state ?? null);
  const tableProps = getTableProps(refreshTableData, searchQuery);
  const [isLoading, setIsLoading] = useState(true);
  const [resetPageIndex, setResetPageIndex] = useState(false);
  const [pageSize, setPageSize] = useState(tableProps.options.pageSize);

  useEffect(() => {
    if (state?.error) {
      setSnack({
        isOpen: true,
        message: state.error,
        severity: 'error'
      });
      window.history.replaceState({}, '');
      setSearchQuery(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTableData = async (tableQuery) => {
    setIsLoading(true);
    const { pageSize, page } = tableQuery;
    let pageIndex = page;

    // To prevent out of bounds errors, pageIndex
    // should be reset whenever fetching a new query.
    if (resetPageIndex) {
      pageIndex = 0;
      setResetPageIndex(false);
    }

    let range = getRange(pageIndex, pageSize);

    if (searchQuery?.back) {
      range = searchQuery.range;
      pageIndex = searchQuery.page;
      delete searchQuery.back;
      delete searchQuery.page;
    };

    try {
      const urlsResponse = await fetchURLs({ range: getRange(pageIndex, pageSize) });
      setSearchQuery((prevState) => ({
        ...prevState,
        range: range,
        page: pageIndex
      }));
      const urlPromises = urlsResponse.data.map((url) => fetchURL(url.id));
      const urlResponses = await Promise.all(urlPromises);
      const updatedData = urlsResponse.data.map((url, index) => {
        return {
          ...url,
          url_endpoint_data: {
            current_analysis: urlResponses[index]?.data?.current_analysis,
            owner: urlResponses[index]?.data?.owner
          }
        };
      }
      );
      setIsLoading(false);
      return {
        data: updatedData,
        page: pageIndex,
        totalCount: getItemsCount(urlsResponse.headers['content-range'])
      };
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <MaterialTable
        title=''
        {...tableProps}
        options={{ ...tableProps.options, pageSize, toolbar: false }}
        components={{ ...tableProps.components }}
        onChangeRowsPerPage={setPageSize}
        data={getTableData}
        tableRef={tableRef}
        isLoading={isLoading}
        localization={localization}
      />
    </Paper>
  );
}
