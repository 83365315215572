import React from 'react';
import { LinearProgress, Typography } from '@material-ui/core';
import { ANALYSIS_STATUS } from 'entities/analysis/analysis.constants';
import useStyles from './ThreatScore.styles';
import { propTypes } from './ThreatScore.props';

function ThreatScore ({ rowData }) {
  const threatScore = rowData?.current_analysis?.risk_score || null;
  const classes = useStyles({ value: threatScore });

  const status = rowData.current_analysis ? rowData.current_analysis.status : null;
  const isScanning = status === ANALYSIS_STATUS.RUNNING || status === ANALYSIS_STATUS.PENDING;

  return (
    <div>
      {isScanning && (
        <div>
          <Typography variant="body4">Scanning</Typography>
          <LinearProgress
            color="primary"
            classes={{
              bar: `${classes.bar}`,
              colorPrimary: classes.grey,
              root: classes.rootProgress
            }}
          />
        </div>
      )}
      {!isScanning && threatScore !== null && (
        <div>
          <Typography variant="body1">{threatScore}</Typography>
          <LinearProgress
            color="primary"
            classes={{
              bar: `${classes.bar} ${classes.barColor}`,
              colorPrimary: classes.grey,
              root: classes.rootProgress
            }}
            variant="determinate"
            value={threatScore}
          />
        </div>
      )}
      {!isScanning && threatScore === null && (
        <div>
          <Typography variant="body4">Unknown</Typography>
        </div>
      )}
    </div>
  );
}

ThreatScore.propTypes = propTypes;
export default ThreatScore;
