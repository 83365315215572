import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useSnackSetState } from 'contexts/SnackContext';
import { useAuth } from 'react-oidc-context';
import makeStyles from './styles';

const useStyles = makeStyles();

const AuthLink = ({ text }) => {
  const [t] = useTranslation();
  const { signinRedirect } = useAuth();
  const setSnack = useSnackSetState();
  const classes = useStyles();

  const signInKeycloak = async () => {
    try {
      await signinRedirect();
    } catch (error) {
      console.error('Error occurred during signIn:', error);
      setSnack({
        isOpen: true,
        title: 'Error',
        message: 'Authentification provider failed, please contact the support',
        severity: 'error'
      });
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    signInKeycloak();
  };

  return (
    <Typography
      display="inline"
      component="a"
      href="/"
      className={classes.link}
      onClick={handleClick}
    >
      {t(text)}
    </Typography>
  );
};

AuthLink.propTypes = {
  text: PropTypes.string
};

export default AuthLink;
