import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useAuth } from 'react-oidc-context';
import { ReactComponent as Logo } from 'assets/qflow_logo.svg';
import LanguageSwitcher from 'components/LanguageSwitcher';
import { InfoIcon, UserSettings } from 'common/Icons';
import LogoutButton from './components/LogoutButton';
import InfoButton from './components/InfoButton';
import { AuthLink } from './components/AuthLink';
import Notification from './Notification';
import { ROUTE_PATHS } from 'utils/routes.constants';
import makeStyles from './styles';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';

const useStyles = makeStyles();

const LoginOrRegisterLink = ({ text }) => {
  return <u><AuthLink text={text} /></u>;
};

LoginOrRegisterLink.propTypes = {
  text: PropTypes.string
};

const UserSettingsButton = () => {
  const navigate = useNavigate();
  const iconStyle = {
    width: '1.5rem',
    height: '1.5rem'
  };

  const onClick = () => {
    navigate(ROUTE_PATHS.USER_SETTINGS);
  };

  return (
    <IconButton onClick={onClick}>
      <UserSettings style={iconStyle}/>
    </IconButton>
  );
};
export default function Bar () {
  const classes = useStyles();
  const { isSaasModeEnabled } = useAppConfigContext();
  const auth = useAuth();

  const icon = () => {
    return (
      <div className={classes.boxIcon}>
        <InfoIcon className={classes.infoicon}/>
      </div>
    );
  };

  const isGuestUser = !auth?.user?.profile?.roles || auth?.user?.profile?.roles.length === 0;

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.mainBar}>
        <div>
          <div className={classes.logo}>
            <Link to={ROUTE_PATHS.SCAN}>
              <Logo />
            </Link>
          </div>
        </div>
        <Notification />
        { isGuestUser && <div>
          <Typography>{icon()}
            <Trans i18nKey={'features_are_restricted'}>
              Features are restricted.
              {isSaasModeEnabled ? <LoginOrRegisterLink text={'login'} /> : <LoginOrRegisterLink text={'login_or_register'} />}
               to gain full access
            </Trans>
          </Typography>
        </div> }
        <div className={classes.statusBar}>
          <>
            <InfoButton />
            { !isGuestUser && <UserSettingsButton />}
            { !isGuestUser && <LogoutButton /> }
            <LanguageSwitcher />
          </>
        </div>
      </div>
    </Toolbar>
  );
}
