import React from 'react';
import { Link } from 'react-router-dom';
import { Zip } from 'common/Icons';
import { Tooltip } from '@material-ui/core';
import { getColor } from '../../ArchivesSidebar.utils';
import { propTypes } from './FolderItem.props';
import makeStyles from './FolderItem.styles';

export default function FolderItem ({ archives, handleArchiveClick, prevSearchQuery }) {
  const { parent } = archives;
  const { name, id, safety, type } = parent;

  const useStyles = makeStyles();
  const classes = useStyles();

  const color = getColor(safety?.toLowerCase());
  const backgroundColor = classes[safety?.toLowerCase() + 'Background'];

  return (
    <Link className={classes.link} to={`/ui/details/${type}/${id}`} state={{ prevSearchQuery }} onClick={handleArchiveClick}>
      <Tooltip title={name} classes={{ tooltip: classes.tooltip + ' ' + backgroundColor }}>
        <div className={classes.itemWrapper}>
          <Zip className={classes.folderIcon} color={color} />
          <div className={classes.folderName}>{name}</div>
        </div>
      </Tooltip>
    </Link>
  );
}
FolderItem.propTypes = propTypes;
