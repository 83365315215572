import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Link, Typography, Tooltip, IconButton, LinearProgress } from '@material-ui/core';
import SecurityPill from './components/SecurityPill/SecurityPill';
import RiskGauge from './components/RiskGauge/ThreatGauge';
import PrivacyPill from './components/PrivacyPill/PrivacyPill';
import Tags from 'pages/MyFiles/components/Tags';
import { InfoIcon, ContentCopy, DownloadFile, Zip } from 'common/Icons';
import { downloadFileContent, fetchFileContent } from 'entities/files/files.api';
import ZipDownloadForm from 'pages/MyFiles/components/DownloadZip/ZipDownloadForm';
import DialogButtons from 'components/DialogButtons';
import { useClipboard, useDialog } from 'hooks';
import { useSnackSetState } from 'contexts/SnackContext';
import { getLastScan } from '../DetailsPanel/DetailsPanel.utils';
import { filterDataByType, getFlatList } from '../../Details.utils';
import NoResult from '../NoResult';
import { checkIsFailed, checkIsScanning } from './Header.utils';
import { propTypes } from './Header.props';
import { UNKNOWN } from './Header.constants';
import makeStyles from './Header.styles';
const useStyles = makeStyles();

const Header = ({ target = {}, results = [], isFile, workflow, fetchTargetData }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const copy = useClipboard();
  const setSnack = useSnackSetState();
  const [showApkQualifiers, setShowApkQualifiers] = useState(false);
  const [tags] = useState(target.tags || []);
  const [id] = useState(target.id || []);
  const submitButtonRef = useRef(null);
  const security = target?.current_analysis?.security;
  const privacy = target?.current_analysis?.privacy;
  const isScanning = useMemo(() => checkIsScanning(target), [target]);
  const isFailed = useMemo(() => checkIsFailed(target), [target]);
  const submittedBy = useMemo(() => target?.owner?.traits?.username ?? UNKNOWN, [target]);
  const ip = useMemo(() => !isFile && getFlatList(filterDataByType(results, 'IpInfo'))?.[0]?.ip, [isFile, results]);
  let domainName;
  const deletedFile = (target.size === undefined && isFile);
  const canDownloadFile = (!deletedFile && isFile);

  useEffect(() => {
    if (workflow === 'apk-scan' && !isFailed) {
      setShowApkQualifiers(true);
    }
  }, [workflow, isFailed]);

  const copyHash = (hash, hashType) => {
    copy({ value: hash });
    setSnack({
      isOpen: true,
      title: hashType + ' ' + t('hash_copied_to_clipboard'),
      severity: 'success'
    });
  };

  try {
    domainName = new URL(target.url);
    domainName = domainName.hostname.replace('www.', '');
  } catch {
    domainName = null;
  };

  const downloadFile = async (file) => {
    try {
      const response = await fetchFileContent(file.id, {});
      downloadFileContent(response.data, file);
    } catch (error) {
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        severity: 'error'
      });
    }
  };

  const tagsSection = <Tags tags= {tags} fileId={id} onChange={() => fetchTargetData()}/>;

  const onDisplayZipDialog = useDialog(`download-as-zip-dialog-${target.id}`, {
    onClose: () => onDisplayZipDialog({ isOpen: false }),
    components: {
      Icon: <Zip className={classes.dialogIconSize} title={t('zip')} />,
      Title: <Typography variant='h4' className={classes.title} align="center">{t('the_file_you_are_about_to_download_may_contain_malicious_code')}</Typography>,
      Content: <ZipDownloadForm submitButtonRef={submitButtonRef} handleDialogClose={() => onDisplayZipDialog({ isOpen: false })} file={target}/>,
      Actions: (
        <div className={classes.dialogButtonsWrapper}>
        <DialogButtons
          confirmLabel={t('download')}
          cancelLabel={t('cancel')}
          onConfirm={() => submitButtonRef.current.click()}
          onCancel={() => onDisplayZipDialog({ isOpen: false })}
        />
        </div>
      )
    }
  });

  return (
    <div className={classes.container}>
       {!isScanning && <RiskGauge target={target}/>}
      <div className={classes.information}>
        <Grid container justify='space-between'>
          <Grid item className={classes.head}>
            <div className={classes.targetContainer}>
              {!canDownloadFile && isFile && <><span className={classes.targetName}>{target.name}</span>{tagsSection}</>}
              {canDownloadFile && isFile &&
                <>
                  <span className={classes.targetName}>{target.name}</span>
                  <Tooltip title={t('download_file')} arrow placement='top'>
                    <IconButton
                      aria-label="Download File"
                      onClick={() => downloadFile(target)}
                    >
                      <DownloadFile color='#057FDC' className={classes.fileIcon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('download_as_zip')} arrow placement='top'>
                    <IconButton
                      aria-label="Download File as zip"
                      onClick={() => onDisplayZipDialog({ isOpen: true })}
                    >
                      <Zip color='#057FDC' className={classes.folderIcon} />
                    </IconButton>
                  </Tooltip>
                 {tagsSection}
                </>}
                {!isFile && <>
                  <span className={classes.targetName}>{target.url}</span>
                  {tagsSection}</>
                }
              </div>
            </Grid>
          {!deletedFile
            ? <Grid item>
              {target.md5 && <Link href="#" underline='none' color='secondary' onClick={() => copyHash(target.md5, t('md5'))} className={classes.hashLinks}>
                {t('md5')} <ContentCopy title={t('copy_to_clipboard')} size={14} />
              </Link>}
              {target.sha1 && <Link href="#" underline='none' color='secondary' onClick={() => copyHash(target.sha1, t('sha1'))} className={classes.hashLinks}>
                {t('sha1')} <ContentCopy title={t('copy_to_clipboard')} size={14} />
              </Link>}
              {target.sha256 && <Link href="#" underline='none' color='secondary' onClick={() => copyHash(target.sha256, t('sha256'))} className={classes.hashLinks}>
                {t('sha256')} <ContentCopy title={t('copy_to_clipboard')} size={14} />
              </Link>}
            </Grid>
            : <Typography color="primary">
              <div className={classes.deletedFileInfo}>
                {t('file_deleted')}
                <Tooltip title={t('file_deleted_explanation')} arrow>
                  <IconButton>
                    <InfoIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              </div>
            </Typography>
          }
        </Grid>
        <div data-test-id='columns' className={classes.columns}>
          {!isFile && <div data-test-id='column' className={classes.column}>
            <p className='title'>{t('ip')}</p>
            <p className='content'>
              {ip ?? <NoResult message={t('not_defined_abbrev')} />}
            </p>
          </div>}
          {!isFile && <div data-test-id='column' className={classes.column}>
            <p className='title'>{t('domain_name')}</p>
            <p className='content'>
              {domainName ?? <NoResult message={t('not_defined_abbrev')} />}
            </p>
          </div>}
          {target.size && <div data-test-id='column' className={classes.column}>
            <p className='title'>{t('size_bytes')}</p>
            <p className='content'>{target.size}</p>
          </div>}
          {target.mimetype && <div data-test-id='column' className={classes.column}>
            <p className='title'>{t('mime_type') }</p>
            <p className='content'>{target.mimetype}</p>
          </div>}
          <div data-test-id='column' className={classes.column}>
            <p className='title'>{t('submitted_by')}</p>
            <p className='content'>{submittedBy}</p>
          </div>
          {workflow && <div data-test-id='workflow' className={classes.column}>
            <p className='title'>{t('workflow')}</p>
            <p className='content'>{workflow}</p>
          </div>}
          <div data-test-id='column' className={classes.column}>
            <p className='title'>{t('last_scan')}</p>
            <p className='content'>{getLastScan(target)}</p>
          </div>
        </div>
        <div className={classes.qualifierContainer} data-test-id='qualifierSection'>
          {showApkQualifiers &&
            <>
              <SecurityPill security={security} isScanning={isScanning} />
              <PrivacyPill privacy={privacy} isScanning={isScanning} />
            </>
          }
        </div>
        {isScanning && <LinearProgress/>}
      </div>
    </div>);
};

Header.propTypes = propTypes;

export default Header;
