import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/* mui deps */
import {
  Grid,
  Typography,
  Button,
  Checkbox,
  Tooltip,
  IconButton
} from '@material-ui/core';

/* dependencies */
import { FileDropZone } from 'common/FileDropZone';
import { DropZoneTypography } from './DropzoneTypography';
import { List } from 'components/List';
import FileListElement from './FileListElement';

/* hooks */
import { useAuthUser } from 'hooks';
import { Trans, useTranslation } from 'react-i18next';

/* context */
import useScanContext from 'contexts/ScanContext/useScanContext';
import { SCAN_ACTIONS } from 'contexts/ScanContext/ScanContext';

import { fetchFlows } from 'entities/flows/flows.api';
import WorkflowList from './components/WorkflowList';

/* utilities */
import { FILE_MAX_SIZE } from '../../../constants';
import { getDefaultFlow } from './components/WorkflowList/WorkflowList.utils';
import { scanner } from 'utils';
import { FileText, InfoIcon, OutlineSave, LockOutlinedIcon } from 'common/Icons';
import { useTheme } from '@material-ui/core/styles';
import makeStyles from './styles';

const useStyles = makeStyles();
export default function SelectFiles ({ onHide, disabled = false }) {
  const { palette } = useTheme();
  const [t] = useTranslation();
  const [scanContext, setScanContext] = useScanContext();
  const [filesList, setFilesList] = useState(() => [...scanContext.files]);
  const [flowsList, setFlowsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [advancedScanSelected, setAdvancedScanSelected] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState([]);
  const { isUserLogged, checkIfAdvancedUser } = useAuthUser();
  const classes = useStyles();
  const { saveTmpFiles, createFile } = scanner;

  const scanFileTranslation = t('scan_file');
  const isGuestUser = !isUserLogged();
  const isAdvancedUser = checkIfAdvancedUser();
  const hasFilesUploaded = filesList.length > 0;

  const ADVANCED_SCAN_DISPLAY_NAME = t('advanced_scan');
  const ADVANCED_SCAN_DESCRIPTION = t('assign_probes_individually');
  const canChooseWorkflow = (!isGuestUser && isAdvancedUser);
  const showWorkflows = (canChooseWorkflow && hasFilesUploaded && !loading);

  useEffect(() => {
    setFilesList([...scanContext.files]);
  }, [scanContext.files]);

  useEffect(() => {
    const advancedScanFlow = {
      display_name: ADVANCED_SCAN_DISPLAY_NAME,
      name: 'default',
      description: ADVANCED_SCAN_DESCRIPTION
    };
    fetchFlows()
      .then(({ data }) => {
        const flowsList = [...data, advancedScanFlow];
        setFlowsList(flowsList);
        const defaultFlow = getDefaultFlow(flowsList);
        setSelectedFlow([defaultFlow]);
        setScanContext({ type: SCAN_ACTIONS.SELECT_FLOWS, payload: 'default' });
        setLoading(false);
      })
      .catch(() => {
        setScanContext({ type: SCAN_ACTIONS.SELECT_FLOWS, payload: 'default' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const flow = selectedFlow[0]?.display_name;
    if (flow === ADVANCED_SCAN_DISPLAY_NAME) {
      setAdvancedScanSelected(true);
    } else {
      setAdvancedScanSelected(false);
    }
  }, [selectedFlow, ADVANCED_SCAN_DISPLAY_NAME]);

  const getAcceptedFiles = (files) => {
    setFilesList(prevFiles => {
      return files.map(f => createFile(f)).concat(prevFiles);
    });
  };

  const startScanFiles = () => {
    saveTmpFiles(filesList);
    setScanContext({ type: SCAN_ACTIONS.START_SCAN, payload: [...filesList] });
  };

  const removeItem = (file) => {
    setFilesList((prevFiles) => prevFiles.filter(f => {
      return !(f.instance.name === file.instance.name && f.temporal_Id === file.temporal_Id);
    }));
  };

  const updatePassword = (file, password) => {
    for (const f of filesList) {
      if (f.instance.name === file.instance.name && f.temporal_Id === file.temporal_Id) {
        f.password = password;
      }
    };
  };

  const openAdvScanView = () => {
    setScanContext({ type: SCAN_ACTIONS.SELECT_FILES, payload: [...filesList] }); // update context state after select all the files
    onHide();
  };
  const handleFlowSelect = (event) => {
    setSelectedFlow([event.target.value]);
    setScanContext({ type: SCAN_ACTIONS.SELECT_FLOWS, payload: event.target.value.name });
  };

  const handleKeepFile = () => {
    setScanContext({ type: SCAN_ACTIONS.TOGGLE_KEEP_FILE });
  };

  return (
    <Grid container justify="center" alignItems="center">
      <Grid container direction="row" justify="center" justifyContent="center" alignItems="center" spacing={1} lg={8} xl={6}>
        <Grid item xs={8} style={{ textAlign: 'center' }}>
          <FileText size={90}/>
          <Typography
            variant="body1"
            align="center"
            gutterBottom
          >
            <Trans i18nKey="scan_and_analyze_files">
              Upload, scan and analyze <strong>files</strong> or <strong>archives</strong> for malware
            </Trans>
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <FileDropZone
            maxSize={FILE_MAX_SIZE}
            onDropCallback={getAcceptedFiles}
            multiple={!isGuestUser}
            className={`${classes.dropzoneContent} ${disabled && classes.disabledDropzone}`}
            amountOfFiles={filesList.length}
            disabled={disabled}
          >
            <DropZoneTypography />
          </FileDropZone>
        </Grid>
        <Grid item xs={10} className={classes.listBox}>
          {hasFilesUploaded && (
            <>
            <div className={classes.passwordExplanationContainer}>
              <Typography variant="body1" align="center" className={classes.passwordExplanation}>
              {t('password_explanation')}
                 <LockOutlinedIcon className={classes.explanationIconSize} color={palette.secondary.main}/>
              </Typography>
            </div>
              <div className={classes.scanHeader}>
                <Typography variant="h4" gutterBottom className={classes.header}>{t('files')}</Typography>
              </div>
            </>
          )}
          <List aria-label="file preview list">
            {
              filesList.map(file => <FileListElement
                key={`${file.instance.name}+${file.temporal_Id}`}
                file={file}
                removeItem={removeItem}
                updatePassword={updatePassword}
              />)
            }
          </List>
          </Grid>
          {showWorkflows &&
          <Grid item xs={10} className={classes.workFlowContainer}>
            <WorkflowList selectedFlow={selectedFlow} handleFlowSelect={handleFlowSelect} flowsList={flowsList} />
          </Grid>}
          {
            hasFilesUploaded && <>
            <Grid item container justify="space-between" xs={10} style={{ padding: '15px' }}>
              <div className={classes.keepFileComponent}>
                <OutlineSave size={24} className={classes.saveIcon} />
                <Typography variant="body" className={classes.keepFileLabel} >{t('save_file_after_analysis')}</Typography>
                <Tooltip title={t('default_message_keep_file')} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} arrow>
                  <IconButton>
                    <InfoIcon className={classes.infoIcon}/>
                  </IconButton>
                </Tooltip>
              </div>
              <Checkbox color="primary" className={classes.keepFileCheckbox} onChange={handleKeepFile}/>
            </Grid>
            <Grid item container justify="center" xs={10}>
              <Grid item xs={6}>
              {advancedScanSelected
                ? (
                <Button
                  variant='contained'
                  fullWidth
                  className={classes.scanButton}
                  onClick={openAdvScanView}
                  color='primary'
                >
                  {t('next')}
                </Button>)
                : (
                  <Button
                    variant='contained'
                    fullWidth
                    className={classes.scanButton}
                    onClick={startScanFiles}
                    color='primary'
                  >
                    {scanFileTranslation}
                  </Button>
                  )}
              </Grid>
            </Grid>
            </>
          }
      </Grid>
    </Grid>
  );
}

SelectFiles.propTypes = {
  onHide: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};
