import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import { useSnackSetState } from 'contexts/SnackContext';
import { fetchFile, fetchMyFiles } from 'entities/files/files.api';
import { TableLocalization } from 'common/tableConfig';
import PageHeader from 'pages/components/PageHeader';
import { getRange, getItemsCount } from '../pages.utils';
import SearchContainer from './components/Search/SearchContainer';
import DeleteFilesAction from './components/DeleteFilesAction';
import getTableProps from './tableProps';
import { INITIAL_SEARCH_QUERY } from './MyFiles.constants';
import { parseFile } from './MyFiles.utils';
import makeStyles from './styles';

const useStyles = makeStyles();

const initialQuery = INITIAL_SEARCH_QUERY.BASIC;

export default function MyFiles () {
  const [t] = useTranslation();
  const localization = TableLocalization();
  const setSnack = useSnackSetState();

  const { state } = useLocation();
  const classes = useStyles();
  const tableRef = useRef(null);
  const refreshTableData = () => tableRef.current.onQueryChange();
  const [searchQuery, setSearchQuery] = useState(state?.error ? initialQuery : (state ?? initialQuery));
  const { columns, options, actions, components } = getTableProps(refreshTableData, searchQuery);
  const [pageSize, setPageSize] = useState(options.pageSize);
  const [totalItemsInPage, setTotalItemsInPage] = useState(0);
  const [isSelectionCancelled, setIsSelectionCancelled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [resetPageIndex, setResetPageIndex] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (state?.error) {
      setSnack({
        isOpen: true,
        message: state.error,
        severity: 'error'
      });
      window.history.replaceState({}, '');
      setSearchQuery(initialQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSelectionChange = useCallback(rows => setSelected(rows.map(parseFile)), []);

  const resetSelected = useCallback(() => setSelected([]), []);

  const handleOnCancelSelection = useCallback(() => {
    setSelected([]);
    setIsSelectionCancelled(true);
  }, []);

  const handleOnPageChange = useCallback(() => {
    resetSelected();
  }, [resetSelected]);

  const handleOnSearch = query => {
    setSearchQuery(query);
    setResetPageIndex(true);
    refreshTableData();
    resetSelected();
  };

  const getTableData = async (tableQuery) => {
    setIsLoading(true);
    const { pageSize, page } = tableQuery;
    let pageIndex = page;

    if (resetPageIndex) {
      pageIndex = 0;
      setResetPageIndex(false);
    };

    let range = getRange(pageIndex, pageSize);

    if (searchQuery.back) {
      range = searchQuery.range;
      pageIndex = searchQuery.page;
      delete searchQuery.back;
      delete searchQuery.page;
    };

    try {
      const filesResponse = await fetchMyFiles({ ...searchQuery, range: range });
      setSearchQuery((prevState) => ({
        ...prevState,
        range: range,
        page: pageIndex
      }));
      setTotalItemsInPage(filesResponse.data?.length);
      const filePromises = filesResponse.data.map((file) => fetchFile(file.id));
      const fileResponses = await Promise.all(filePromises);
      const updatedData = filesResponse.data.map((file, index) => {
        return {
          ...file,
          file_endpoint_data: {
            current_analysis: fileResponses[index]?.data?.current_analysis,
            owner: fileResponses[index]?.data?.owner
          }
        };
      }
      );
      setIsLoading(false);
      return {
        data: updatedData,
        page: pageIndex,
        totalCount: getItemsCount(filesResponse.headers['content-range'])
      };
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  /**
   * Control the table checkboxes in order
   * to cancel them from the outside (cancel link).
   */
  const handleSelectionProps = useCallback(({ tableData }) => {
    if (selected.length === 0 && isSelectionCancelled) {
      tableData.checked = false;
      setIsSelectionCancelled(false);
    }
    return tableData.checked;
  }, [isSelectionCancelled, selected.length]);

  const isHeaderChecked = useMemo(
    () =>
      selected.length === 0 ? false : selected.length === totalItemsInPage,
    [selected.length, totalItemsInPage]
  );

  return (
    <Grid item xs={12}>
      <PageHeader title= {t('files')} />
      <Paper className={classes.paper} elevation={0}>
        <SearchContainer onSearch={handleOnSearch} searchQuery={searchQuery} />
        <DeleteFilesAction files={selected} refreshData={refreshTableData} onCancelSelection={handleOnCancelSelection}/>
        <MaterialTable
          data={getTableData}
          onChangeRowsPerPage={setPageSize}
          title={t('files')}
          tableRef={tableRef}
          columns={columns}
          options={{
            ...options,
            pageSize,
            selectionProps: handleSelectionProps,
            headerSelectionProps: {
              checked: isHeaderChecked
            }
          }}
          actions={actions}
          components={components}
          isLoading={isLoading}
          localization={localization}
          onSelectionChange={handleOnSelectionChange}
          onChangePage={handleOnPageChange}
        />
      </Paper>
    </Grid>
  );
};
