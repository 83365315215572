import React from 'react';
import { useTranslation } from 'react-i18next';
import { Date } from 'common/Table/Cells';
import ThreatScore from 'pages/components/ThreatScore';
import NameColumn from './components/NameColumn';
import CellMenu from './components/CellMenu';
import SubmittedBy from './components/SubmittedBy';
import Tags from 'pages/MyFiles/components/Tags';
import AnalysisStatus from 'pages/components/AnalysisStatus';
import tableConfig from 'common/tableConfig';

const GetUrlNameColumn = (searchQuery) => {
  const [t] = useTranslation();
  return {
    title: t('url_name'),
    field: 'url',
    width: '35%',
    cellStyle: {
      ...tableConfig.options.cellStyle,
      borderBottom: '1px solid #e0e0e0',
      maxWidth: 300,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    // eslint-disable-next-line react/display-name
    render: rowData => <NameColumn rowData={rowData} searchQuery={searchQuery}/>
  };
};

const GetUrlOwnerColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('submitted_by'),
    width: '10%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: rowData =>
      <SubmittedBy owner={rowData.url_endpoint_data.owner}/>
  };
};

const GetUrlScanDate = () => {
  const [t] = useTranslation();
  return {
    title: t('last_scan_date'),
    field: 'date',
    width: '20%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: rowData => <Date rowData={rowData}/>
  };
};

const GetRiskScoreColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('threat_score'),
    width: '10%',
    sorting: false,
    cellStyle: {
      ...tableConfig.options.cellStyle,
      borderBottom: '1px solid #e0e0e0',
      maxWidth: 130,
      width: 130
    },
    render: rowData => {
      return <ThreatScore rowData={rowData}/>;
    }
  };
};

const GetStatusColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('status'),
    width: '60px',
    sorting: false,
    cellStyle: {
      ...tableConfig.options.cellStyle,
      borderBottom: '1px solid #e0e0e0',
      width: '60px'
    },
    render: rowData => <AnalysisStatus rowData={rowData.url_endpoint_data} />
  };
};

const GetUrlTagsColumn = (refreshTableData) => {
  const [t] = useTranslation();
  return {
    title: t('tags'),
    width: '15%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: rowData => <Tags tags={rowData?.tags || []} fileId={rowData?.id} onChange={refreshTableData}/>
  };
};

const GetMenuColumn = (refreshTableData) => {
  const [t] = useTranslation();
  return {
    title: t('actions'),
    width: '15%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: (rowData) => <CellMenu tags={rowData.tags} url={rowData} onChange={refreshTableData} />
  };
};

export const getColumns = (refreshTableData, searchQuery) => {
  return [
    GetUrlNameColumn(searchQuery),
    GetUrlOwnerColumn(),
    GetRiskScoreColumn(),
    GetStatusColumn(),
    GetUrlScanDate(),
    GetUrlTagsColumn(refreshTableData),
    GetMenuColumn(refreshTableData)
  ];
};
